<template>
  <div>
    <MyHeader :title="$t('lang.menu_passrate')" :ShowBack="true" :BackUrl="backUrl" RightIcon="search"
      @rightclick="onRightClick" />
    <van-tabs @change="onChange" v-model="mType">
      <van-tab :title="$t('lang.passrate')" key="1" />
      <van-tab :title="$t('lang.missrate')" key="2" />
      <van-tab :title="$t('lang.timerate')" key="3" />
      <van-tab :title="$t('lang.earlyrate')" key="4" />
      <van-tab :title="$t('lang.laterate')" key="5" />
      <van-tab :title="$t('lang.shortrate')" key="6" />
      <van-tab :title="$t('lang.longrate')" key="7" />
    </van-tabs>
    <van-list v-model="loading" :finished="finished" :finished-text="$t('lang.listend')" :immediate-check="false"
      direction="down" @load="onLoad">
      <van-cell v-for="mData in lstData" :key="mData.id" :title="mData.title"
        :label="mData.label == undefined ? '' : mData.label" enabled @click="showDetail(mData)">
        <template #right-icon>
          <van-tag type="warning" size="medium" class="custtag">{{ (mData.showRate * 100).toFixed(2) }}%</van-tag>
          <van-tag color="#CC99CC" size="medium" class="custtag">{{
            mData.showTimes
          }}</van-tag>
        </template>
      </van-cell>
    </van-list>
    <!--div
      v-show="showHint"
      style="margin: 20px; text-align: center; display: block; color: #969799"
    >
      {{ $t("lang.nodata") }}
    </div-->
    <van-popup v-model="showMenu" round position="center" :style="{ width: '95%', height: '300px' }" @opened="showChart">
      <div id="myChart" :style="{
            width: '90%',
            height: '98%',
            margin: '20px',
            padding: '0 0 0 10',
          }"></div>
    </van-popup>
  </div>
</template>
<style scoped>
.custtag {
  height: 24px;
  margin-left: 5px;
  min-width: 20px;
}
</style>
<script>
import MyHeader from "@/components/Header.vue";
import { GetPass, ExportPatrolRateList } from "@/api/index.js";
import { getSessionObj } from "@/utils/session.js";

let echarts = require("echarts/lib/echarts");
require("echarts/lib/chart/bar");
require("echarts/lib/component/title");

export default {
  components: { MyHeader },
  data() {
    return {
      showMenu: false,
      mType: "1",

      lstData: [],
      loading: false,
      finished: false,
      page: 1,
      backUrl: "",
      //showHint: true,

      mRecord: {},
    };
  },

  mounted() {
    if (!this.$store.getters.getIsXunjianAPP)
      this.backUrl = "/total/index";
    else
      this.backUrl = "/query/appindex";
    //console.log("mounted");
  },

  activated() {
    //console.log("activated");
    if (this.$route.params.p) {
      if (this.$route.params.p.event == "export") this.Export();
      else {
        this.mType = "1";
        this.page = 1;
        this.lstData = [];
        this.Query();
      }
    }
  },

  deactivated() {
    //console.log("deactivated");
  },

  methods: {
    onRightClick() {
      this.$router.push({
        name: "QueryForm",
        params: { title: this.$t("lang.menu_passrate"), f: "Pass" },
      });
    },

    onChange(index) {
      let lst = this.lstData;
      for (let i = 0; i < lst.length; i++) {
        switch (index) {
          case 0:
            lst[i].showRate = lst[i].qualifiedRate; //合格率
            lst[i].showTimes = lst[i].qualifiedTimes;
            break;
          case 1:
            lst[i].showRate = lst[i].missRate; //漏检率
            lst[i].showTimes = lst[i].missTimes;
            break;
          case 2:
            lst[i].showRate = lst[i].onTimeRate; //准时率
            lst[i].showTimes = lst[i].onTimeTimes;
            break;
          case 3:
            lst[i].showRate = lst[i].earlyRate; //早到率
            lst[i].showTimes = lst[i].earlyTimes;
            break;
          case 4:
            lst[i].showRate = lst[i].lateRate; //迟到率
            lst[i].showTimes = lst[i].lateTimes;
            break;
          case 5:
            lst[i].showRate = lst[i].shortRate; //间隔短
            lst[i].showTimes = lst[i].shortTimes;
            break;
          case 6:
            lst[i].showRate = lst[i].longRate; //间隔长
            lst[i].showTimes = lst[i].longTimes;
            break;
          default:
            lst[i].showRate = lst[i].missRate; //漏检率
            lst[i].showTimes = lst[i].missTimes;
        }
      }
    },

    Query() {
      let p = null;
      if (
        this.$route.params.p != undefined &&
        this.$route.params.p.pagesize != 1
      )
        p = this.$route.params.p;
      if (!p) p = getSessionObj("Query_Params");
      if (!p) {
        //this.showHint = true;
        return;
      }

      let that = this;
      that.loading = true;

      GetPass(
        {
          token: this.$store.getters.getToken,
          startTime: p.mDate1,
          endTime: p.mDate2,
          strCompanyIds: p.mCompany,
          strDepartmentIds: p.mDept,
          strRouteIds: p.mLine,
          strPlaceIds: p.mAddr,
          strGroupIds: p.mGroup,
          strInspectorIds: p.mUser,
          statMode: p.mTotalType,
          page: this.page,
          limit: p.pagesize,
        },
        (ret) => {
          that.loading = false;
          if (ret && ret.code == 0) {
            if (ret.count == 0) {
              that.finished = true;
              //that.showHint = false;
            } else {
              //填充
              let tmpDate = ret.data;
              for (let j = 0; j < tmpDate.length; j++) {
                tmpDate[j].showRate = tmpDate[j].qualifiedRate;
                tmpDate[j].showTimes = tmpDate[j].qualifiedTimes;
              }
              that.lstData.push(...tmpDate);
              //this.showHint = that.lstData.length == 0;

              //判断是否全部加载完成
              if (that.lstData.length < ret.count) {
                that.page = that.page + 1;
                that.finished = false;
              } else {
                that.finished = true;
                //that.showHint = false;
              }
            }
          } else {
            that.finished = true;
            this.$Toast(!ret ? this.$t("lang.queryfail") : ret.message);
          }
        }
      );
    },

    Export() {
      let p = null;
      if (
        this.$route.params.p != undefined &&
        this.$route.params.p.pagesize != 1
      )
        p = this.$route.params.p;
      if (!p) p = getSessionObj("Query_Params");
      if (!p) return;

      let par = {
        url:
          ExportPatrolRateList() +
          "?token=" +
          this.$store.getters.getToken +
          "&startTime=" +
          p.mDate1 +
          "&endTime=" +
          p.mDate2 +
          "&strCompanyIds=" +
          p.mCompany +
          "&strDepartmentIds=" +
          p.mDept +
          "&strRouteIds=" +
          p.mLine +
          "&strPlaceIds=" +
          p.mAddr +
          "&strGroupIds=" +
          p.mGroup +
          "&strInspectorIds=" +
          p.mUser +
          "&statMode=" +
          p.mTotalType +
          "&page=1&limit=1000000",
        fileName:
          "合格率统计_" +
          this.$Tools.FormatDate("YYYYmmdd_HHMMSS", new Date()) +
          ".xls",
      };

      //console.log(JSON.stringify(par));
      try {
        window.downloadFile.postMessage(JSON.stringify(par));
        //window.openByBrowser.postMessage(par.url);
      } catch (e) {
        console.log(e);
      }
    },

    onLoad() {
      this.Query();
    },

    showDetail(m) {
      this.mRecord = m;
      this.addrName = m.title;
      this.showMenu = true;
    },

    showChart() {
      let myChart = echarts.init(document.getElementById("myChart"));
      myChart.setOption({
        title: { text: this.addrName },
        color: ["#ff976a"],
        //tooltip: {},
        xAxis: {
          type: "value",
        },
        grid: {
          x: 50,
          x2: 20,
          y2: 50,
        },
        yAxis: {
          type: "category",
          data: [
            this.$t("lang.passrate"),
            this.$t("lang.missrate"),
            this.$t("lang.timerate"),
            this.$t("lang.earlyrate"),
            this.$t("lang.laterate"),
            this.$t("lang.shortrate"),
            this.$t("lang.longrate"),
          ],
        },
        series: [
          {
            type: "bar",
            label: {
              show: true,
              position: "insideLeft",
              color: "#323233",
              formatter: function (params) {
                return params.value + "%";
              },
            },
            data: [
              (this.mRecord.qualifiedRate * 100).toFixed(2),
              (this.mRecord.missRate * 100).toFixed(2),
              (this.mRecord.onTimeRate * 100).toFixed(2),
              (this.mRecord.earlyRate * 100).toFixed(2),
              (this.mRecord.lateRate * 100).toFixed(2),
              (this.mRecord.shortRate * 100).toFixed(2),
              (this.mRecord.longRate * 100).toFixed(2),
            ],
          },
        ],
      });
    },
  },
};
</script>